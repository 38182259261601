<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h3>Envoyer un nouveau message</h3>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <select
          required
          name="theme"
          id="theme"
          class="form-control form-control-lg form-control-flat"
          v-model="theme"
          @change="onThemeChange"
        >
          <option selected disabled value=""
            >Quel est l'objet de la demande ?</option
          >
          <option value="Question technique">Question technique</option>
          <option value="Demande de rappel">Demande de rappel téléphonique</option>
          <option value="Commande garantie non reçue">Commande garantie non reçue</option>
          <option value="Prise en charge SAV Grands Comptes">Demande de prise en charge SAV (Grands Comptes)</option>
          <option value="Autre">Autre</option>
        </select>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">
        <span class="font-weight-bold">Message</span>
      </div>
      <div class="col-md-9">
        <textarea
          class="form-control"
          rows="4"
          v-model="message"
          required
        ></textarea>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">Pièce jointe <small>(facultatif)</small></div>
      <div class="col-md-9">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="uploadSending"
          v-on:vdropzone-success="uploadSuccess"
          v-on:vdropzone-error="uploadError"
          v-on:vdropzone-removed-file="fileRemoved"
        >
        </vue-dropzone>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-3">&nbsp;</div>
      <div class="col-md-9">
        <button
          type="submit"
          class="btn btn-flat btn-primary"
          v-bind:class="{ disabled: !formIsValid }"
          @click.prevent="sendMessage"
        >
          Envoyer
        </button>
      </div>
    </div>
    <FullScreenMessage
      v-if="sending"
      heading="Nous enregistrons votre message..."
      message="Veuillez patienter ..."
    />
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Toast from "vue-toastification";
import FullScreenMessage from "../../Common/FullScreenMessage";
const options = {};
Vue.use(Toast, options);

export default {
  name: "ContactForm",
  components: {
    FullScreenMessage,
    vueDropzone: vue2Dropzone,
  },
  props: {
    username: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      options: [],
      dropzoneOptions: {
        paramName: "attachment",
        acceptedFiles: "image/jpeg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xls, application/xlsx, application/x-xls",
        url: "/api/v5/support/messages/uploadAttachment",
        addRemoveLinks: true,
        maxFiles: 1,
        thumbnailWidth: 140,
        thumbnailHeight: 140,
        dictDefaultMessage:
          "Déposez votre fichier ici ou cliquez pour parcourir...",
        dictRemoveFile: "Supprimer",
      },
      theme: "",
      message: "",
      attachment: null,
      subjectMinLength: 3,
      messageMinLength: 3,
      sending: false,
    };
  },
  created() {
    this.getMessagesThemes();
  },
  computed: {
    formIsValid: function() {
      return this.theme != "" && this.message != "";
    },
  },
  methods: {
    getMessagesThemes() {
      const options = axios
        .get("/api/v5/support/messages/themes")
        .then((response) => {
          console.log(response.data);
          this.options = response.data;
        });
    },
    uploadSending(file, xhr, formData) {
      formData.append("username", this.username);
    },
    uploadSuccess(file, response) {
      this.attachment = response.attachment;
    },
    fileRemoved() {
      // remove file from server
      // if (this.localFile1) {
      //   axios
      //     .get("/api/v2/tickets/" + this.ticket.id + "/remove-file/1")
      //     .then((response) => {
      //       this.localFile1 = null;
      //     });
      // }
    },
    uploadError(file, message) {},
    onThemeChange(event) {
      this.theme = event.target.value;
    },
    sendMessage() {
      this.sending = true;
      axios
        .post("/support/contact", {
          theme: this.theme,
          subject: this.subject,
          message: this.message,
          attachment: this.attachment,
        })
        .then((response) => {
          this.$toast.info("Votre message a bien été envoyé.");
          this.clearForm();
          window.location = response.data.redirect;
        });
    },
    clearForm() {
      this.theme = "";
      this.subject = "";
      this.message = "";
      this.sending = false;
    },
  },
};
</script>
